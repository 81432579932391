
import ValidationMixin from "@/mixins/validation";
import { AddSystemUser, UserTypes } from "@/store/modules/user/user.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import passwordGenerator from "generate-password";

const userX = namespace("User");

@Component({
  components: {
    DepartmentSelect: () =>
      import("@/components/department/DepartmentSelect.vue"),
    RoleSelect: () => import("@/components/role/RoleSelect.vue"),
  },
})
export default class AddUser extends Mixins(ValidationMixin) {
  @userX.State(UserTypes.ADD_USER_STATE)
  public addUser!: DefaultState;

  @userX.Mutation(UserTypes.SET_ADD_USER_DIALOG)
  public setAddUser!: (addUser: boolean) => void;

  @userX.Action(UserTypes.ADD_USER)
  public saveUser!: (farmer: AddSystemUser) => Promise<void>;

  public addUserStep = 1;

  public isValid = false;

  public user: AddSystemUser = {
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    phone: "",
    password: "",
    active: true,
  };

  @Ref("form") public formRef!: Validator;

  closeAddUserDialog(): void {
    this.setAddUser(false);
  }

  async saveUserToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.saveUser(this.user);
    }
  }

  mounted(): void {
    this.user.password = passwordGenerator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      lowercase: true,
      uppercase: true,
      strict: true,
      excludeSimilarCharacters: true,
    });
  }
}
